<style lang="scss">
    .notification {
        background-color: white;
        padding: 1rem;
        border-radius: 4px;
    }
    .modal-content {
        text-align: center;
    }
    .is-child {
        padding: 2rem;
    }
    .VueCarousel {
        padding: 0.2rem;
        background-color: white;
        min-height: 8rem;
        margin:3rem 0;

        button.VueCarousel-navigation-button {
            height: 8rem;
            background-color: white;
            border-radius: 4px 0px 0px 4px;
        }
        button.VueCarousel-navigation-button.VueCarousel-navigation-next {
            border-radius: 0px 4px 4px 0px;
        }
    }
</style>

<template>
    <div>
        <div class="modal is-active" v-if="imgPopup.show">
            <div class="modal-background" v-on:click="imgPopup.show = false"></div>
                <div class="modal-content">
                    <p class="image">
                        <img v-bind:src="imgPopup.url">
                    </p>
                </div>
            <button class="modal-close is-large" aria-label="close" v-on:click="imgPopup.show = false"></button>
        </div>

        <p class="notification">
            Most of my work happens in the background. I develop and speed up applications, configure servers, and recover from disasters.
            I have however completed a variety of projects which have front-end aspects - some large, some small - A selection can be seen below.
        </p>

        <div class="tile is-ancestor">
            <div class="tile is-parent is-vertical">
                <div class="tile is-child">
                    <h2 class="title">Asset Management System</h2>
                    <carousel :navigationEnabled="true" :paginationEnabled="false" :navigationClickTargetSize="15">
                        <slide>
                            <img alt="screenshot" src='../images/asset.png' v-on:click="popUp($event.target)">
                        </slide>
                        <slide>
                            <img alt="screenshot" src='../images/asset1.png' v-on:click="popUp($event.target)">
                        </slide>
                        <slide>
                            <img alt="screenshot" src='../images/asset2.png' v-on:click="popUp($event.target)">
                        </slide>
                        <slide>
                            <img alt="screenshot" src='../images/asset3.png' v-on:click="popUp($event.target)">
                        </slide>
                        <slide>
                            <img alt="screenshot" src='../images/asset4.png' v-on:click="popUp($event.target)">
                        </slide>
                    </carousel>

                    <p>This full-featured asset management system allows Stanmore College to keep track of valuable assets throughout their life cycle within the organisation. Its features include:</p>
                    <ul>
                        <li>Add assets from Purchase Orders</li>
                        <li>Check assets out and in to track movement</li>
                        <li>Record damage to assets against checkouts/users</li>
                        <li>Digitally sign equipment loan agreements</li>
                        <li>Find assets which meet certain criteria (including data pulled from SCCM such as CPU/RAM/Installed Software)</li>
                        <li>Report on assets</li>
                        <li>Decomission assets</li>
                    </ul>
                    <p>People within the organisation use this system to keep tabs on where things are, who has them, and when they are damaged. This saves a great deal of time and money.</p>
                    <p>I developed this with ASP.Net Core, Entity Framework, Vue and Bootstrap.</p>
                </div>
                <div class="tile is-child">
                        <h2 class="title">Data-Driven Website</h2>
                        <carousel :perPage="2" :navigationEnabled="true" :paginationEnabled="false" :navigationClickTargetSize="15">
                            <slide>
                                <img alt="screenshot" src='../images/sc1.jpg' v-on:click="popUp($event.target)">
                            </slide>
                            <slide>
                                <img alt="screenshot" src='../images/sc2.jpg' v-on:click="popUp($event.target)">
                            </slide>
                            <slide>
                                <img alt="screenshot" src='../images/sc3.jpg' v-on:click="popUp($event.target)">
                            </slide>
                        </carousel>

                        <p>The website for Stanmore College was aging, a pain to update, and slow.</p>
                        <p>I replaced it with a modern, responsive framework which draws its data directly from the College's information management system.</p>
                        <p>My work here ensures data entry no longer needs to be duplicated, staff time is saved, and the public can find information more easily.</p>
                        <p>I developed this with ASP.Net Core, Dapper, Bootstrap and Vue.</p>
                </div>
            </div>
            
            <div class="tile is-parent is-vertical">
                <div class="tile is-child">
                    <h2 class="title">Student Companion App</h2>
                    <carousel :perPageCustom="[[0, 1], [1024, 4]]" :navigationEnabled="true" :paginationEnabled="false" :navigationClickTargetSize="15">
                        <slide>
                            <img alt="screenshot" src='../images/app.png' v-on:click="popUp($event.target)">
                        </slide>
                        <slide>
                            <img alt="screenshot" src='../images/app2.png' v-on:click="popUp($event.target)">
                        </slide>
                        <slide>
                            <img alt="screenshot" src='../images/app3.png' v-on:click="popUp($event.target)">
                        </slide>
                        <slide>
                            <img alt="screenshot" src='../images/app4.png' v-on:click="popUp($event.target)">
                        </slide>
                    </carousel>

                    <p>Designed to replace expensive SMS notifications and timetable printing, this mobile, desktop and web app for students and their parents allows:</p>
                    <ul>
                        <li>Easy viewing of important learner data in a graphical format</li>
                        <li>Instant messaging tutors and other key staff</li>
                        <li>Viewing and digitally signing documents</li>
                        <li>Viewing a live timetable</li>
                        <li>Seeing in real time whether your child is in their lesson</li>
                        <li>Tracking progress towards goals</li>
                    </ul>
                    <p>Thousands of learners and their parents use this app to great effect.</p>
                    <p>I developed this with ASP.Net Core, Dapper, Angular and Ionic.</p>
                </div>
                <div class="tile is-child">
                    <h2 class="title">NFC Registration System</h2>
                    <div class="card-content content">
                        <p>I designed and implemented a new NFC card system for Stanmore College using off the shelf hardware and a bespoke set of back-end systems and reports.</p>
                        <p>This system pulls data about a detected card (such as whether a person should be on site, and whether they are scheduled to be in a particular location), writes data back and provides an audio visual response within 10ms.</p>
                        <p>Students swipe their ID cards at readers at the entrance and in classrooms. These swipes are recorded in a highly optimised SQL Server instance. The system has a number of uses, for example it provides real-time information to security, allowing them to detain people if something is wrong.</p>
                        <p>The data generated by this system allows teaching staff to instantly mark registers, discover if students are on-site, and more.</p>
                        <p>I developed this with .Net Core and Dapper.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'portfolio',

        data () {
            return {
                imgPopup: {
                    show: false,
                    url: ''
                }
            }
        },

        methods: {
            popUp (el) {
                console.log(el)
                let url = el.getAttribute('src')
                this.imgPopup.url = url
                this.imgPopup.show = true
            }
        }
    }
</script>