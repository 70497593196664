<style scoped>
    li {
        display: list-item;
        list-style-type: disc;
        list-style-position: inside;
        margin: 0.1rem 0;
    }
</style>

<template>
    <div>
        <div class="columns is-multiline is-variable is-8">
            <div class="column is-half">
                <h2 class="title">Software Development</h2>
                <p>I provide full software design and implementation services. I work with following languages in no particular order:</p>
                <ul >
                    <li>C</li>
                    <li>C++</li>
                    <li>C# (.Net and .Net Core)</li>
                    <li>Python</li>
                    <li>PHP</li>
                    <li>Ruby</li>
                    <li>JavaScript</li>
                </ul>
            </div>

            <div class="column is-half">
                <h2 class="title">Disaster Recovery</h2>
                <p>If something goes wrong, I can usually help you recover.</p>
                <ul>
                    <li>Malware Removal</li>
                    <li>Security Audits</li>
                    <li>Data Migrations</li>
                    <li>DDoS Mitigation</li>
                    <li>Misc. Data Recovery Services</li>
                </ul>
            </div>
            <div class="column is-half">
                <h2 class="title">Database Design / Analysis</h2>
                <p>I have worked with the following technologies, although in principle I can work with any:</p>
                <ul>
                    <li>MySQL / MariaDB</li>
                    <li>Microsoft SQL Server</li>
                    <li>Postgres</li>
                    <li>MongoDB</li>
                </ul>
                <p>I offer design, performance tuning, integration and reporting services.</p>
            </div>
            <div class="column is-half">
                <h2 class="title">QA / Maintenance</h2>
                <p>If you're worried about the quality of work you have been provided or just need things updated, I can help with:</p>
                <ul>
                    <li>Code Reviews</li>
                    <li>Audits / Analysis</li>
                    <li>Library Updates / Compatibility</li>
                    <li>Performance Tuning</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'services'
    }
</script>