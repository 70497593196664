<style scoped>
    .pgp {
        font-size: 8px;
    }
    .clickable {
        cursor: pointer;
    }
    .panel, .panel * {
        border: none;
    }
    .panel > * {
        padding: 0.8em;
    }
</style>

<template>
    <div>
        <div class="columns is-multiline is-variable is-8">
            <div class="column is-half">
                <p>My name is Carlton and, in short, I design, build and fix complicated software.</p>
                <p>I have been writing software for as long as I can remember, and I have been doing professional software engineering and DBA work for over 10 years.</p>
                <p>I currently work for a large consulting company.</p>
            </div>
            <div class="column is-half">
                <h2 class="title">Contact</h2>
                <ul class="list">
                    <a class="clickable" href="mailto:work@cdownie.co.uk">
                        <i class="fas fa-envelope"></i> work@cdownie.co.uk
                    </a>
                    <a class="clickable" v-if="!showPGP" v-on:click="showPGP = true">
                        <i class="fas fa-lock"></i> Show PGP Public Key
                    </a>
                    <div v-if="showPGP">
                        <i class="fas fa-lock"></i>
                        <pre class="pgp">{{ pgpKey }}</pre>
                    </div>
                    <span>
                        <i class="fas fa-map-marker-alt"></i> London, UK
                    </span>
                    <a class="clickable" rel="me" href="https://social.cdownie.co.uk/@carl">
                        <i class="fa-brands fa-twitter"></i> Mastodon
                    </a>
                </ul>
            </div>            
        </div>
    </div>
</template>

<script>
    export default {
        name: 'home',
        data () {
            return {
                showPGP: false,
                pgpKey: `
-----BEGIN PGP PUBLIC KEY BLOCK-----
xsBNBFro1eEBCADI/dOrY1jBHEhTspzQm97WFY2mq1OyZ6VKlr50VE0eijTE
nEBlzUZ6mePP5hDUlbT/GlgRFPU/gmcSTNbWyYurZWEK07T3CttuMFrkfcEE
zsbPSbSJ9jBqSYSp+F4Bj6TfjMbDlVHE489cv7rtUKMUdWmUE1UIGLdVMCqb
eYIZrfxX0qJ+SfTP9u1b5/w7qTrp0/G8SU8a45XPHXZwChA/5Pd+qgextt2D
IIfZejImnNtaY2OQxVvLbvRxNxSmJSyi0Wk7SjBIOlnyCDDxoXW5RClwnuGS
KFKeNb4aT57KYYy1UCvFQBXgiC3UqTBH+pbsL70utd2V4fEM8kC6iLeDABEB
AAHNJ3dvcmtAY2Rvd25pZS5jby51ayA8d29ya0BjZG93bmllLmNvLnVrPsLA
fwQQAQgAKQUCWujV5QYLCQcIAwIJEKxSBfB2PiPlBBUICgIDFgIBAhkBAhsD
Ah4BAAoJEKxSBfB2PiPlntAH/0yt1WtvBR0RXfYE2LA1T7fs2vpI8NQizi+3
I95AyiLMSHt67jigpt8hKMgs/XJCyBWOx5rQ1mJXYf4knvzVdo4GqmLGWApf
21MZtyXjtoYa6FBxJxoAx1ulBeXyl5KORM9pp69heHYhCOEY0M0+uq1zWYuB
SN+paGazxE4e9925MbfyjA66Jwx88V3fFkpRbS+baUJxWqWvc2em4GzTHWC3
zZHaKsJF7aNNF3StG+SgDXsP/cHOoKBlm6OJYoeTW1GrsbHcJ8zhD45hqnaw
XvXrT2/mUV7+0e+S4LA2fY7xZ6IOWwwhWxK+KpmDNL3KFMk7PTto0OM6gYRA
JCMT/zbOwE0EWujV4QEIALGzVo6AIkiysjO/9QlJRcyMnOSGWxMhRLEmE8Pg
5miVftI95u/v4CO+LJ+aBjpF6Bt9utB0imDhU5JWvPID0aEuktRXKIc5ozuE
RA86EoqkteZEo8bWW+TdQ1OSV0hefILqqF2JviAPUZYJDgNmR3/8sf7icx00
3hpwuJEgh7dH3Mc7UOBLpRMUaTWfqoh1ju4SF3lcW75Hj4T/stVXvFIbj5w0
NszJVJAsJmUJi3elTsH4LlY7D5J/yB0qrBtSLk7wCnQA8Tjxgyv/Y/pIA1hP
2BdrHo16T4JX0T1W+hV+uUWJ6jgwZZVgMutH4CixtBG2LQEsZHhHc3yp3o0/
/8sAEQEAAcLAaQQYAQgAEwUCWujV5wkQrFIF8HY+I+UCGwwACgkQrFIF8HY+
I+Vw1ggAmmjd0cYb/I2++BIUgqbSXmFjX+6//etDTKOzyivwFNwdTA4cLA3G
IpQ8gTPe0R8FuPGbz0jL3nNnfvMsm4eOAK12AM1DjDpd7/qEcbKDVjyWKeBh
WLEavK0k419wVpteglgcVKSe4AaNq1VN7MRgkDNI5BO1QAepqp1JegMmWLTS
U2OoECsXvC2ptXsX3OhGhD5OtxXpDOlc8Q7hVEFkAwhX8RcN2stbN521HFnh
CbyM2w8+bT5lDSgtLG/fAiXA5WUFSuwVvRDPb1OhzQCn6YgKvTkGtFMvCKX2
hmee6oXokNVHfiTzUxlPJLOMiac8MXw+hpAT06Fr8s5CTMAGMQ==
=LM5J
-----END PGP PUBLIC KEY BLOCK-----
                `
            }
        }
    }
</script>
