<style lang="scss">
    @import url('https://fonts.googleapis.com/css?family=Oswald&display=swap');

    $light-shade: #f5f8f0;
    $light-accent: #f19c5b;
    $brand: #d25860;
    $accent-dark: #6cbde9;
    $dark-shade: #245684;
    
    $body-background-color: $light-shade;
    $link-focus-border: $accent-dark;
    $text: $dark-shade;
    $text-light: $light-accent;
    $text-strong: $dark-shade;
    $title-color: $brand;
    $subtitle-color: $brand;

    $family-primary: 'Lato', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    $body-font-size: 1.1rem;

    $tabs-toggle-link-active-background-color: $brand;
    $tabs-toggle-link-active-border-color: $light-accent;

    $primary: $brand;

    $section-padding: 1rem;
    $column-gap: 1rem;
    

    $modal-content-width: 1024px;

    @import "node_modules/bulma/sass/utilities/_all.sass";

    @import "node_modules/bulma/sass/base/_all.sass";

    @import "node_modules/bulma/sass/layout/footer.sass";
    @import "node_modules/bulma/sass/layout/section.sass";

    @import "node_modules/bulma/sass/elements/container.sass";
    @import "node_modules/bulma/sass/elements/content.sass";
    @import "node_modules/bulma/sass/elements/title.sass";
    @import "node_modules/bulma/sass/elements/button.sass";
    
    @import "node_modules/bulma/sass/components/tabs.sass";
    @import "node_modules/bulma/sass/components/modal.sass";

    @import "node_modules/bulma/sass/grid/columns.sass";
    @import "node_modules/bulma/sass/grid/tiles.sass";

    @import "@fortawesome/fontawesome-free/css/fontawesome.css";
    @import "@fortawesome/fontawesome-free/css/solid.css";

    .fas {
        color: $light-accent;
        margin-right: 0.5em;
    }

    footer a[href] {
        color: #0062ff;
    }

    .title, .subtitle {
        font-family: 'Oswald', sans-serif;
    }

    header {
        .title {
            font-size: 12vmin;
            text-align: right;
        }
        .subtitle {
            font-size: 7vmin;
            text-align: right;
        }
    }

    h2 {
        font-size:1.1em;
        font-weight:bold;
    }

    .section {
        margin:2rem 0;
    }

    .list > * {
        margin: 1rem 0;
        display: block;
        width: 100%;
        padding:0.5rem;
    }

    .block-spaced {
        margin-top: 5rem;
        margin-bottom: 2rem;
    }

    p {
        margin-bottom: 1.5rem;
    }
    .clear {
        clear: both;
    }

    .columns.is-variable.is-8 {
        --columnGap: 5rem;
    }

    $glitch-from: -1;
    $glitch-to: 2;
    $glitch-opacity: 0.8;

    @keyframes glitch {
        0% {
            transform: translateX(0);
            opacity: 1;
        }
        10% {
            transform: translateX(#{$glitch-from+ random($glitch-to)}px);
            opacity: $glitch-opacity;
        }
        20% {
            transform: translateX(#{$glitch-from+ random($glitch-to)}px);
            opacity: 1;
        }
        30% {
            transform: translateX(#{$glitch-from+ random($glitch-to)}px);
            opacity: 1;
        }
        40% {
            transform: translateX(#{$glitch-from+ random($glitch-to)}px);
            opacity: 1;
        }
        50% {
            transform: translateX(#{$glitch-from+ random($glitch-to)}px);
            opacity: 1;
        }
        60% {
            transform: translateX(#{$glitch-from+ random($glitch-to)}px);
            opacity: 1;
        }
        70% {
            transform: translateX(#{$glitch-from+ random($glitch-to)}px);
            opacity: $glitch-opacity;
        }
        80% {
            transform: translateX(#{$glitch-from+ random($glitch-to)}px);
            opacity: 1;
        }
        90% {
            transform: translateX(#{$glitch-from+ random($glitch-to)}px);
            opacity: 1;
        }
        100% {
            transform: translateX(#{$glitch-from+ random($glitch-to)}px);
            opacity: 1;
        }
        
    }

    svg {
        .st0{fill:#D25860;}
        .st1{fill:#F19C5B;}
        .st2{fill:#6CBDE9;}
        .st3{fill:#72C9E5;}

        .glitch-c {
            @for $i from 1 through 6 {
                path:nth-child(#{$i}) {
                    animation: glitch #{(1 / random(3)) + 1}s linear infinite #{random(2)/10}s;
                }
            }
        }
    }
</style>

<template>
    <div>
        <div class="container">
            <header>
                <section class="section">
                    <div class="columns">
                        <div class="column is-one-third">
                            <svg version="1.1" id="logo" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 662.4 474.7" xml:space="preserve">
                            <g id="letters" transform="translate(4.9169951e-6,-3.2275389e-6)">
                                <g id="D">
                                    <path id="path189" class="st0" d="M384.1,323.2
                                        l85.9-0.2c-0.2,29.7,0,39.4,0,42.7c0,6.7-0.1-14.4-0.2,43.1l129.6-158.7L469.7,73.5l0.3,95.7h-85.6c-1.3-56.7,0.4-111.8-1.2-168.5
                                        L527.4,0c34.3,34.9,61.6,61.4,83.3,83.3c57.7,86.8,37.6,91.5,37.1,150.9c-1,29.3-2.8,60.8-2.3,86.2l-41,72.9
                                        c-44.2,45.6-10.7,11.4-78.6,80.7c-45.7,0.7-52.1,0.7-110.1,0.6l-31.1,0C383.8,424.8,384.1,374.4,384.1,323.2z"/>
                                    <path id="path1515" class="st1" d="M579.3,221.9l6.7,17.8
                                        l8.9,10.9l-9,9l-9,18.9l18-22.4l4.5-6.1L579.3,221.9z"/>
                                    <path id="path1198" class="st1" d="M383.2,0.7l1.2,168.5h24.7
                                        l0.5-168.8L383.2,0.7z"/>
                                    <path id="path1198-0" class="st1" d="M384.1,323.2L384,474.6l25.2,0
                                        l-0.1-151.3L384.1,323.2z"/>
                                    <path id="path1221" class="st2" d="M470,169.3
                                        c-0.2-32.3-0.2-63.9-0.3-95.7l-3.2,0.1l0,95.7"/>
                                    <path id="path1221-3" class="st2" d="M470,408.8
                                        c0-56.7,0-56.8,0-85.8l-3.7,0l0.2,85.8L470,408.8"/>
                                    <path id="path1402" class="st2" d="M499,0h28.4
                                        c211.5,129.6,146,389.7-1.5,474.6h-44.3c123.3-69,167-158.2,164.1-244.2C642.6,141.5,590.7,56,499,0z"/>
                                </g>
                                <g id="C" class="glitch-c">
                                    <path id="path99" class="st1" d="M71.4,405.6l-3-78H0
                                        l0-21.5h68.4c0-52.6-0.5-76.7-1.8-158.3C70,88,67.2,46.4,120.5,18c13.5-7.1,34.2-15.4,50.6-16.5l7.5-0.5
                                        c-29.1,7.2-38.5,30.4-50.4,51.9c-23.3,29,2.9,341,11,373.9c5.1,44.4,16.9,48.3-19.6,46.4C104,472.7,74.4,470.4,71.4,405.6
                                        L71.4,405.6z"/>
                                    <path id="path101" class="st2" d="M1.6,295.7v-7h7.5h7.5v7v7H9.1H1.6V295.7z"/>
                                    <path id="path214" class="st3" d="M222,266.4v-8.8h8.3h8.3l-0.3,8.5l-0.3,8.5l-8,0.3l-8,0.3
                                        L222,266.4z"/>
                                    <path id="path222" class="st2" d="M331,152.8v-17.6h16.5H364v17.6v17.6h-16.5H331V152.8z"/>
                                    <path id="path247" class="st1" d="M234,131.8c0-21.4,0.4-39,0.9-39c1.8,0,4.7,4.2,6.9,10
                                        c2.9,7.7,4.9,36.9,3.7,55l-0.8,12.9h-5.4H234V131.8z"/>
                                    <path id="path249" class="st1" d="M220.1,362.7v-36.4h12.6h12.6v32.9v32.9l-4.6,3
                                        c-3.7,2.5-6.1,3.1-12.6,3.4l-8.1,0.4L220.1,362.7z"/>
                                    <path id="path251" class="st2" d="M330.9,90.1c-2.1-35.9-12.6-57.6-34.8-71.5
                                        c-9.7-6.1-6-5.7,7.2,0.8c33.1,16.2,54.6,43.8,59.6,76.2l1,6.3h-16.1h-16.1L330.9,90.1z"/>
                                    <path id="path253" class="st2" d="M331.7,372.6v-47.2h15.9h15.9v47.2v47.2h-15.9h-15.9V372.6z"
                                        />
                                    <path id="path255" class="st2" d="M290.1,469.3c9.3-5.5,20.8-14.2,25.5-19.2
                                        c2.6-2.8,3-2.9,16.7-2.9c12.6,0,14.1,0.2,14.1,1.9c0,2.9-13.9,15.8-22.6,21l-7.8,4.6l-17.5,0l-17.5,0L290.1,469.3z"/>
                                    <path id="path257" class="st2" d="M183.9,398.8l-4.9-2.5l-0.7-15.9c-0.4-8.8-0.7-41.7-0.8-73.3
                                        l-0.1-57.4h12.1h12.1v54.2c0,29.8-0.3,63.9-0.7,75.8l-0.7,21.6h-5.6C190.8,401.3,187.1,400.4,183.9,398.8L183.9,398.8z"/>
                                    <path id="path259" class="st2" d="M176.7,137.9c0.4-19.8,1.1-34.9,1.8-37.3
                                        c1.8-6,4.6-7.4,14.6-7.4h8.5v39v39h-12.8h-12.8L176.7,137.9z"/>
                                    <path id="main" class="st0" d="
                                    M132.7,468.8c-22.7-10.9-29.2-19.2-35.8-38.5c-6.2-59-2.4-123.9-2.4-184.1c0-113.9-0.1-117.6-0.6-143.8
                                    c-1-127,240.5-138.3,235.3,0.2h-8.1h-8.6v16v16h9.5h7.3v17.8v18.4h-39.9h-40.2c0-26.3-0.1-50,0-68.1c0.7-33.4-73.7-36.8-74.7,0
                                    v58.8v56.9c-10.6,0-15.3,0-25.6,0c-0.2-0.1,0.2,7.1,0.1,15.6l-0.1,13.8l12.5-0.1h13.1v69.6c0,71.2,0,47.8,0,69.9
                                    c-1.3,27.1,73.6,26.2,74.7,2.1c0.7-28.2,0-37.4,0-64.3h43.1h37v40.1c0.4,21.9-0.3,43.2,0,47.4v8.6H302h-28.4l0.1,11.1l-0.1,12.4
                                    h6.5c2.9,0.3,11.8-0.1,21.5,0l17.5,0c-11.3,13.3-27.5,21.2-37.5,25.7l-7.9,3.6l-65.6,0.1l-64.3-0.1L132.7,468.8z"/>
                                </g>
                            </g>
                            </svg>

                        </div>
                        <div class="column">
                            <h1 class="title">Carlton Downie</h1>
                            <h1 class="subtitle">Systems Analyst</h1>
                        </div>
                    </div>
                </section>
            </header>

            <!-- <section class="section clear">
                <div class="tabs is-toggle is-fullwidth">
                    <ul>
                        <router-link to="/" tag="li" active-class="is-active" exact>
                            <a>
                                <span>About</span>
                            </a>
                        </router-link>
                        <router-link to="/services" tag="li" active-class="is-active">
                            <a>
                                <span>
                                    Services
                                </span>
                            </a>
                        </router-link>
                        <router-link to="/work" tag="li" active-class="is-active">
                            <a>
                                <span>Portfolio</span>
                            </a>
                        </router-link>
                    </ul>
                </div>
            </section> -->
            
            <section class="section">
                <router-view></router-view>
            </section>
            
        </div>

        <footer class="footer">
            <div class="container">
                <p>© Carlton Downie</p>
                <a href="mailto:work@cdownie.co.uk">Email Me</a>
            </div>
        </footer>
    </div>
</template>

<script>
    export default {
        name: 'app'
    }
</script>
