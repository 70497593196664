import Vue from 'vue'
import VueRouter from 'vue-router'

import VueCarousel from 'vue-carousel'

import App from './vue/app.vue'
import HomePage from './vue/home.vue'
import PreviousWorkPage from './vue/previouswork.vue'
import ServicesPage from './vue/services.vue'

Vue.use(VueRouter)
Vue.use(VueCarousel)

const routes = [
    { path: '/', component: HomePage },
    // { path: '/projects', component: ProjectsPage },
    { path: '/work', component: PreviousWorkPage },
    { path: '/services', component: ServicesPage }
]

const router = new VueRouter({
    mode: 'history',
    routes // short for `routes: routes`
})

const instance = new Vue({
    el: '#main',
    router,
    template: '<App/>',
    render: h => h(App),
    components: { App }
})